var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"zero"},[_vm._m(0),_c('div',{staticClass:"content"},[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11),_c('view',{staticClass:"zero-bot flex flex-ajcenter flex-column"},[_c('div',{staticClass:"zero-bot__desc flex flex-ajcenter"},[_c('p',[_vm._v("数百万")]),_vm._v("投资者的智慧结晶触手可及 ")]),_c('div',{staticClass:"zero-bot__desc flex flex-ajcenter"},[_vm._v(" 让您的投资之路离专业更近,让您的交易结果离目标更近。 ")])]),_vm._m(12),_c('a',{staticClass:"zero-btn",attrs:{"href":"https://ebcfinsns.co","target":"_blank"}},[_vm._v(" 登录社区 ")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"zero-head"},[_c('div',{staticClass:"head-box flex"},[_c('div',{staticClass:"head-box__logo"},[_c('a',{attrs:{"href":"https://www.ebcfin.com"}},[_c('img',{staticClass:"img",attrs:{"src":require("@/assets/image/No1/Group-19.png"),"title":" EBC官网地址 https://www.ebcfin.com"}})])]),_c('div',{staticClass:"head-box__tit flex-1"},[_c('a',{staticClass:"btns",attrs:{"href":"https://www.ebcfin.com"}},[_vm._v("www.ebcfin.com")])]),_c('div',{staticClass:"head-box__btn flex"},[_c('a',{staticClass:"left-btn",attrs:{"href":"https://ebcfinsns.co","target":"_blank"}},[_vm._v(" 登录社区 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"zero-block1 flex flex-column flex-ajcenter"},[_c('h1',{staticClass:"zero-block1__h1"},[_vm._v("From Zero To Hero")]),_c('div',{staticClass:"flex flex-ajcenter zero-block1__tips"},[_vm._v(" 每个交易员心中都有一个"),_c('p',[_vm._v("英雄梦")])]),_c('div',{staticClass:"zero-block1__title"},[_vm._v(" EBC 智能跟单社区 ")]),_c('div',{staticClass:"zero-block1__img"},[_c('img',{staticClass:"img",attrs:{"src":require("@/assets/image/img/shot-0.png")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"zero-line flex flex-ajcenter"},[_c('div',{staticClass:"zero-line__box flex-1"}),_c('img',{staticClass:"img",attrs:{"src":require("@/assets/image/img/icon-xx.svg")}}),_c('div',{staticClass:"zero-line__box flex-1"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"zero-info flex flex-ajcenter flex-column"},[_c('div',{staticClass:"zero-info__title flex"},[_vm._v(" 优势一：基于MT底层通讯协议，实现"),_c('p',[_vm._v("“毫秒级”")]),_vm._v("跟单 ")]),_c('div',{staticClass:"zero-info__tips flex flex-ajcenter"},[_c('div',{staticClass:"item flex flex-ajcenter"},[_c('img',{staticClass:"img",attrs:{"src":require("@/assets/image/img/icon-xx.svg")}}),_vm._v("非EA插件跟单 ")]),_c('div',{staticClass:"item flex flex-ajcenter"},[_c('img',{staticClass:"img",attrs:{"src":require("@/assets/image/img/icon-xx.svg")}}),_vm._v("跟单无需打开MT ")]),_c('div',{staticClass:"item flex flex-ajcenter"},[_c('img',{staticClass:"img",attrs:{"src":require("@/assets/image/img/icon-xx.svg")}}),_vm._v("免费 ")])]),_c('div',{staticClass:"zero-info__img"},[_c('img',{staticClass:"img",attrs:{"src":require("@/assets/image/img/shot-1.png")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"zero-line flex flex-ajcenter"},[_c('div',{staticClass:"zero-line__box flex-1"}),_c('img',{staticClass:"img",attrs:{"src":require("@/assets/image/img/icon-xx.svg")}}),_c('div',{staticClass:"zero-line__box flex-1"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"zero-info flex flex-ajcenter flex-column"},[_c('div',{staticClass:"zero-info__title flex"},[_vm._v(" 优势二："),_c('p',[_vm._v("“更专业” ")]),_vm._v("的信号选择 ")]),_c('div',{staticClass:"zero-info__tips flex flex-ajcenter"},[_vm._v(" 首次引入基金评级系统。标准差、夏普比、净值余额比...专业评级指标将辅助你选择一个真正优质的信号。 ")]),_c('div',{staticClass:"zero-info__img"},[_c('img',{staticClass:"img",attrs:{"src":require("@/assets/image/img/shot-2.png")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"zero-line flex flex-ajcenter"},[_c('div',{staticClass:"zero-line__box flex-1"}),_c('img',{staticClass:"img",attrs:{"src":require("@/assets/image/img/icon-xx.svg")}}),_c('div',{staticClass:"zero-line__box flex-1"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"zero-info flex flex-ajcenter flex-column"},[_c('div',{staticClass:"zero-info__title flex"},[_vm._v(" 优势三：保护您的"),_c('p',[_vm._v("“交易隐私” ")])]),_c('div',{staticClass:"zero-info__tips flex flex-ajcenter"},[_vm._v(" 公私域信号分离，为交易隐私保驾护航；公域信号引流，私域信号变现，帮助交易者解决市场转化问题。 ")]),_c('div',{staticClass:"zero-info__img"},[_c('img',{staticClass:"img",attrs:{"src":require("@/assets/image/img/shot-3.png")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"zero-line flex flex-ajcenter"},[_c('div',{staticClass:"zero-line__box flex-1"}),_c('img',{staticClass:"img",attrs:{"src":require("@/assets/image/img/icon-xx.svg")}}),_c('div',{staticClass:"zero-line__box flex-1"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"zero-info flex flex-ajcenter flex-column"},[_c('div',{staticClass:"zero-info__title flex"},[_vm._v(" 优势四："),_c('p',[_vm._v("“AI选手”")]),_vm._v("一键筛选信号 ")]),_c('div',{staticClass:"zero-info__tips flex flex-ajcenter"},[_vm._v(" 根据跟单者风险/收益偏好，一键筛选目标信号，轻松复制全球顶级交易策略。 ")]),_c('div',{staticClass:"zero-info__img"},[_c('img',{staticClass:"img",attrs:{"src":require("@/assets/image/img/shot-4.png")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"zero-line flex flex-ajcenter"},[_c('div',{staticClass:"zero-line__box flex-1"}),_c('img',{staticClass:"img",attrs:{"src":require("@/assets/image/img/icon-xx.svg")}}),_c('div',{staticClass:"zero-line__box flex-1"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"zero-info flex flex-ajcenter flex-column"},[_c('div',{staticClass:"zero-info__title flex"},[_vm._v(" 优势五：去伪存真的"),_c('p',[_vm._v("“交易社区”")])]),_c('div',{staticClass:"zero-info__tips flex flex-ajcenter"},[_vm._v(" 集成化交易研究交流社区 ：EBC研究院、订单流学习基地、对冲基金研究策略等，"),_c('br'),_vm._v("助您在交易之路上保持进步，学习成长。 ")]),_c('div',{staticClass:"zero-info__img"},[_c('img',{staticClass:"img",attrs:{"src":require("@/assets/image/img/shot-5.png")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"zero-footer"},[_c('div',{staticClass:"zero-footer__info flex flex-ajcenter"},[_c('img',{staticClass:"img",attrs:{"src":require("@/assets/image/img/icon-xx.svg")}}),_c('div',{staticClass:"info-line flex-1"}),_c('div',{staticClass:"info-desc"},[_vm._v(" 每个认真交易的人都值得被认真对待 ")]),_c('div',{staticClass:"info-line flex-1"}),_c('img',{staticClass:"img",attrs:{"src":require("@/assets/image/img/icon-xx.svg")}})]),_c('div',{staticClass:"zero-footer__logo flex flex-ajcenter"},[_c('img',{staticClass:"img",attrs:{"src":require("@/assets/image/img/icon-6.svg")}})])])
}]

export { render, staticRenderFns }